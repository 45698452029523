<template>
  <v-form @submit.prevent="updateItem" ref="form">
    <v-card>
      <v-card-title
        class="headline primary lighten-1 white--text justify-space-between py-1"
      >
        <span v-if="item">Edit parsing group</span>
        <span v-if="!item">Create parsing group</span>
        <v-btn icon color="white" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-2 px-6">
        <v-overlay
          :absolute="true"
          :value="dataLoading"
          justify-center
          align-center
          opacity="0.15"
        >
          <v-progress-circular
            size="64"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-overlay>
        <v-row>
          <v-col>
            <v-text-field
              name="name"
              :label="attributeLabels.name"
              v-model="form.name"
              type="text"
              :rules="[
                (v) => !!v || 'Required',
                (v) =>
                  (v && v.length >= 3) || 'Should contains at least 3 symbols',
                (v) =>
                  (v && v.length <= 30) || 'Should contains maximum 30 symbols',
              ]"
              :counter="30"
              validate-on-blur
            ></v-text-field>
          </v-col>
          <v-col> </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              name="name"
              :label="attributeLabels.gracePeriodWarn"
              v-model="form.gracePeriodWarn"
              type="text"
              :rules="[(v) => !!v || 'Required', isNumeric]"
              validate-on-blur
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              name="name"
              :label="attributeLabels.gracePeriodErr"
              v-model="form.gracePeriodErr"
              type="text"
              :rules="[(v) => !!v || 'Required', isNumeric]"
              validate-on-blur
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <label
              class="v-label v-label--active theme--light text-body-2 mb-0 pb-0"
              >{{ attributeLabels.schedule }} (Every)</label
            >
            <v-row>
              <v-col>
                <v-text-field
                  name="scheduleValue"
                  v-model="form.scheduleValue"
                  type="text"
                  :rules="[(v) => !!v || 'Required', isNumeric]"
                  validate-on-blur
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  name="scheduleUnit"
                  v-model="form.scheduleUnit"
                  :items="availablePeriodUnits"
                  item-text="value"
                  item-value="key"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <label
              class="v-label v-label--active theme--light text-body-2 mb-0 pb-0"
              >{{ attributeLabels.moveNext }}</label
            >
            <v-row>
              <v-col>
                <v-text-field
                  name="scheduleValue"
                  v-model="form.moveNextRunValue"
                  type="text"
                  :rules="[(v) => !!v || 'Required', isNumeric]"
                  validate-on-blur
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  name="scheduleUnit"
                  v-model="form.moveNextRunUnit"
                  :items="availablePeriodUnits"
                  item-text="value"
                  item-value="key"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-checkbox
          name="isActive"
          :label="attributeLabels.isActive"
          v-model="form.isActive"
          :true-value="true"
          :false-value="false"
        ></v-checkbox>
        <v-alert v-if="hasErrors" type="error" elevation="0" outlined>
          <div v-for="(error, key) in errors" :key="key">
            {{ prepareErrorMessage(error, key) }}
          </div>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="$emit('close')">Close</v-btn>
        <v-btn type="submit" color="primary" text> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import _ from "lodash";
import { SAVE_PARSING_GROUP } from "@/store/modules/parsingGroup/actions";

const defaultValues = {
  gracePeriodErr: 10,
  gracePeriodWarn: 3,
  isActive: true,
  moveNextRunUnit: "d",
  moveNextRunValue: 7,
  name: null,
  scheduleUnit: "h",
  scheduleValue: 1,
};

export default {
  name: "ParsingGroupForm",
  props: {
    item: Object,
  },
  data: function () {
    return {
      dataLoading: false,
      availablePeriodUnits: [
        { key: "min", value: "Minutes" },
        { key: "h", value: "Hours" },
        { key: "d", value: "Days" },
        { key: "w", value: "Weeks" },
        { key: "m", value: "Months" },
      ],
      form: this.item
        ? {
            name: this.item.name,
            gracePeriodErr: this.item.gracePeriodErr,
            gracePeriodWarn: this.item.gracePeriodWarn,
            isActive: this.item.isActive,
            moveNextRunUnit: this.item.moveNextRunUnit,
            moveNextRunValue: this.item.moveNextRunValue,
            scheduleUnit: this.item.scheduleUnit,
            scheduleValue: this.item.scheduleValue,
          }
        : _.merge({}, defaultValues),
      attributeLabels: {
        name: "Parsing group name",
        gracePeriodErr: "Grace period for Source parsing Error, days",
        gracePeriodWarn: "Grace period for Warning, days without articles",
        isActive: "Enabled",
        moveNext: "Move next run",
        schedule: "Parsing interval for a group",
      },
      errors: {},
      parentItems: [],
    };
  },
  computed: {
    hasErrors: function () {
      return !_.isEmpty(this.errors);
    },
  },
  methods: {
    isNumeric: function (v) {
      return (
        (Number(v) !== false &&
          (Number.isInteger(Number(v)) || v === "0") &&
          Number(v) >= 0) ||
        "Should be a valid number"
      );
    },
    prepareErrorMessage(error, key) {
      return error.replace("{attribute}", key);
    },
    updateItem: function () {
      if (this.$refs.form.validate()) {
        this.dataLoading = true;
        const { form } = this;
        this.$store
          .dispatch("parsingGroup/" + SAVE_PARSING_GROUP, {
            data: form,
            id: this.item ? this.item.id : null,
          })
          .then(
            (response) => {
              this.dataLoading = false;
              if (response.result) {
                this.form = defaultValues;
                this.$emit("submitted", response.data);
                this.errors = {};
              } else {
                this.errors = response.details;
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
  },
};
</script>
