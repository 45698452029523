<template>
  <div>
    <h1 class="outline--text d-flex">
      Parsing groups
      <v-spacer></v-spacer>
      <v-btn
        v-if="canManage"
        color="primary"
        small
        rounded
        class="align-self-baseline"
        @click="editItem"
        >New group</v-btn
      >
    </h1>
    <v-row>
      <v-col class="outline--text mt-7" style="max-width: 700px">
        <div>
          <span v-if="!isLoading && this.totalCount > 0">
            <p v-if="this.totalCount > this.currentPage * this.itemsPerPage">
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.currentPage * this.itemsPerPage
              }}
              of {{ this.totalCount }} items
            </p>
            <p v-else>
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.totalCount
              }}
              of {{ this.totalCount }} items
            </p>
          </span>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-space-between">
          <v-spacer></v-spacer>
          <v-pagination
            v-if="showPages && pageCount > 1"
            class="my-3"
            v-model="pagination.page"
            :length="pageCount"
            :total-visible="7"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      id="itemsTable"
      :headers="headers"
      :loading="isLoading"
      :items="groups"
      disable-pagination
      disable-filtering
      disable-sort
      hide-default-footer
    >
      <template v-slot:item.enabled="{ item }">
        <v-switch
          v-model="item.isActive"
          hide-details
          :true-value="true"
          :false-value="false"
          class="pa-0 ma-0"
          @change="toggleGroup(item)"
        ></v-switch>
      </template>
      <template v-slot:item.name="{ item }">
        <div class="d-flex text-no-wrap">
          {{ item.name }}
        </div>
      </template>
      <template v-slot:item.schedule="{ item }">
        <div class="d-flex text-no-wrap">
          Every {{ item.scheduleValue }} {{ getPeriodUnit(item.scheduleUnit) }}
        </div>
      </template>
      <template v-slot:item.moveNextRun="{ item }">
        <div class="d-flex text-no-wrap">
          Every {{ item.moveNextRunValue }}
          {{ getPeriodUnit(item.moveNextRunUnit) }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <div v-if="canManage" class="d-flex">
          <v-btn x-small icon @click="editItem(item)">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-tooltip v-if="item.isDefault" left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                class="ml-3"
                v-bind="attrs"
                v-on="on"
                color="grey lighten-2"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
            <span>Can not delete default group</span>
          </v-tooltip>
          <v-btn
            v-else
            x-small
            icon
            class="ml-3"
            @click="confirmDeleteItem(item)"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-if="selectedItem" v-model="deleteDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between py-1"
        >
          Delete confirmation
          <v-btn icon color="white" @click="deleteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-progress-linear
          v-if="isLoading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-card-text v-if="selectedItem.sourceNumber" class="mt-6">
          It is not possible to remove a parsing group. Add another group to the
          following sources and then delete the current one:
          <router-link
            :to="{
              name: 'SourceListPage',
              query: { parsingGroup: selectedItem.id },
            }"
            target="_blank"
            >Linked sources</router-link
          >
        </v-card-text>
        <v-card-text v-else class="mt-6">
          Are you sure you want to delete {{ selectedItem.name }} parsing group?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deleteDialog = false">Close</v-btn>
          <v-btn color="primary" text @click="deleteItem(selectedItem)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="700px">
      <parsing-group-form
        v-if="editDialog"
        :item="selectedItem"
        @close="closeEditDialog"
        @submitted="
          fetchItems();
          closeEditDialog();
        "
      ></parsing-group-form>
    </v-dialog>
  </div>
</template>

<script>
import { SAVE_PARSING_GROUP } from "@/store/modules/parsingGroup/actions";
const availablePeriodUnits = {
  min: "Minutes",
  h: "Hours",
  d: "Days",
  w: "Weeks",
  m: "Months",
};

import {
  FETCH_PARSING_GROUPS,
  DELETE_PARSING_GROUP,
} from "@/store/modules/parsingGroup/actions";
import { mapGetters, mapState } from "vuex";
import ParsingGroupForm from "@/views/parsingGroup/item/ParsingGroupForm";

import acl from "@/mixin/acl";
import { RESOURCE_PARSING_GROUPS_MANAGEMENT } from "@/store/modules/admin/getters";
export default {
  name: "SubscriptionListPage",
  mixins: [acl],
  components: { ParsingGroupForm },
  data() {
    return {
      isLoading: false,
      showPages: false,

      headers: [
        {
          text: "",
          value: "enabled",
          width: "50",
        },
        {
          text: "ID",
          value: "id",
          width: "50",
        },
        {
          text: "Parsing group name",
          value: "name",
        },
        {
          text: "Source Number",
          value: "sourceNumber",
          width: "150",
        },
        {
          text: "Channels Number",
          value: "channelNumber",
          width: "150",
        },
        {
          text: "Schedule",
          value: "schedule",
        },
        {
          text: "Grace period for Source parsing Error, days",
          value: "gracePeriodErr",
        },
        {
          text: "Move next run",
          value: "moveNextRun",
        },
        {
          text: "Grace period for Warning, days without articles",
          value: "gracePeriodWarn",
        },
        {
          width: "50",
          text: "",
          value: "actions",
        },
      ],
      deleteDialog: false,
      editDialog: false,
      selectedItem: null,
    };
  },
  computed: {
    ...mapGetters("parsingGroup", [
      "groupList",
      "totalCount",
      "currentPage",
      "pageCount",
      "offset",
    ]),
    ...mapState("parsingGroup", ["pagination"]),
    canManage: function () {
      return this.hasAccess([RESOURCE_PARSING_GROUPS_MANAGEMENT]);
    },
    itemsPerPage: function () {
      return this.pagination.rowPerPage;
    },
    groups: function () {
      if (this.isLoading) {
        return [];
      }
      return this.groupList;
    },
  },
  watch: {
    "pagination.rowPerPage": function () {
      this.pagination.page = 1;
    },
  },
  methods: {
    fetchItems: function () {
      const vm = this;
      vm.isLoading = true;
      vm.$store.dispatch(`parsingGroup/${FETCH_PARSING_GROUPS}`).then(
        () => {
          vm.isLoading = false;
          vm.showPages = true;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    confirmDeleteItem: function (item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    deleteItem: function (item) {
      this.isLoading = true;
      this.$store
        .dispatch("parsingGroup/" + DELETE_PARSING_GROUP, item)
        .then(
          () => {
            this.isLoading = false;
            this.selectedItem = null;
            this.deleteDialog = false;
            this.fetchItems();
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    editItem: function (item) {
      this.selectedItem = item;
      this.editDialog = true;
    },
    closeEditDialog: function () {
      this.selectedItem = null;
      this.editDialog = false;
    },
    getPeriodUnit: function (key) {
      return availablePeriodUnits[key] ? availablePeriodUnits[key] : "unknown";
    },
    toggleGroup: function (item) {
      this.$store
        .dispatch("parsingGroup/" + SAVE_PARSING_GROUP, {
          data: { isActive: item.isActive },
          id: item ? item.id : null,
        })
        .then(
          (response) => {
            if (response.result) {
              this.fetchItems();
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  beforeMount() {
    this.fetchItems();
  },
};
</script>

<style scoped></style>
